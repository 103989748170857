import React, { useEffect, useState } from 'react'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { TableWidget, Highlights, TabBar, HStack } from '@revolut/ui-kit'
import Stat from '@src/components/Stat/Stat'
import { RoundSelector } from '@src/apps/People/Engagement/Results/components/RoundSelector'
import { IdAndName } from '@src/interfaces'
import { useSurveyRounds } from '@src/apps/People/Engagement/Results/hooks'
import Loader from '@src/components/CommonSC/Loader'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { ResultComments } from './ResultComments'
import { ResultQuestions } from './ResultQuestions'
import { ResultCategories } from './ResultCategories'
import { ScoreType, ViewType } from '../types'
import { useLocation } from 'react-router-dom'
import { useAdminEngagementResultStats } from '@src/api/engagement'
import {
  overallScoreToColor,
  participationRateToColor,
} from '@src/apps/People/Engagement/helpers'
import { round } from 'lodash'
import { formatPercentage } from '@src/utils/format'

export const SurveyResults = ({ survey }: { survey: EngagementSurveyInterface }) => {
  const { state } = useLocation<{ roundId?: number }>()
  const [selectedRound, setSelectedRound] = useState<IdAndName>()
  const { rounds: options, asyncState } = useSurveyRounds(survey.id)
  const [type, setType] = useState<ScoreType>('engagement')
  const [view] = useState<ViewType>('table')
  const [selectedTable, setSelectedTable] = useState<IdAndName>({
    id: 1,
    name: 'categories',
  })
  const {
    data: stats,
    refetch,
    isLoading,
  } = useAdminEngagementResultStats(selectedRound?.id)

  useEffect(() => {
    if (asyncState === 'ready') {
      let initRound = options.at(0)
      if (state?.roundId) {
        const preselectedRound = options.find(option => option.id === state.roundId)
        if (preselectedRound) {
          initRound = preselectedRound
        }
      }
      setSelectedRound(initRound)
    }
  }, [asyncState])

  useEffect(() => {
    if (selectedRound) {
      refetch()
    }
  }, [selectedRound])

  if (asyncState === 'pending') {
    return <Loader />
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <Highlights>
          <RoundSelector
            showHorizontalNavigation={false}
            value={selectedRound}
            surveyId={survey?.id}
            onChange={value => {
              if (value?.id) {
                setSelectedRound(value)
              }
            }}
          />
          <Stat
            color={overallScoreToColor(stats?.nps_score)}
            val={
              isLoading ? undefined : stats?.nps_score ? round(stats.nps_score, 2) : 'N/A'
            }
            label="Engagement Index"
          />
          <Stat
            val={
              isLoading
                ? undefined
                : stats?.total_headcount
                ? stats.total_headcount
                : 'N/A'
            }
            label="Audience size"
            tooltip="This is the number of active employees who were requested to complete the survey"
          />
          <Stat
            val={
              isLoading
                ? undefined
                : stats?.participation_rate
                ? formatPercentage(stats.participation_rate, 2)
                : 'N/A'
            }
            label="Participation"
            color={participationRateToColor(
              stats?.participation_rate
                ? Math.round(stats.participation_rate * 100)
                : undefined,
            )}
          />
        </Highlights>
      </TableWidget.Info>
      <TableWidget.Actions>
        <HStack gap="s-16">
          {/* 
          TODO https://revolut.atlassian.net/browse/REVC-6347
          at the moment we just display table version
          <TabBar
            variant="segmented fit"
            mx="auto"
            value={view}
            onChange={value => {
              setView(value || 'table')
            }}
          >
            <TabBar.Item to="chart" useIcon="AvatarGrid" />
            <TabBar.Item to="table" useIcon="Menu" />
          </TabBar> */}
          {selectedTable.name !== 'Comments' ? (
            <TabBar
              variant="segmented fit"
              mx="auto"
              value={type}
              onChange={value => {
                setType(value || 'engagement')
              }}
            >
              <TabBar.Item to="engagement">Engagement</TabBar.Item>
              <TabBar.Item to="average">Average</TabBar.Item>
            </TabBar>
          ) : null}
        </HStack>
      </TableWidget.Actions>
      <TableWidget.Filters>
        <RadioSelectInput
          inputProps={{ width: 240 }}
          label="Analyse"
          searchable={false}
          value={selectedTable}
          options={[
            { value: { id: 1, name: 'Categories' }, label: 'Categories' },
            { value: { id: 2, name: 'Questions' }, label: 'Questions' },
            { value: { id: 3, name: 'Comments' }, label: 'Comments' },
          ].filter(Boolean)}
          onChange={value => {
            if (value) {
              setSelectedTable(value)
            }
          }}
        />
      </TableWidget.Filters>
      <TableWidget.Table>
        {selectedTable.name === 'Comments' ? (
          <ResultComments view={view} selectedRound={selectedRound} />
        ) : selectedTable.name === 'Questions' ? (
          <ResultQuestions view={view} scoreType={type} selectedRound={selectedRound} />
        ) : (
          <ResultCategories view={view} scoreType={type} selectedRound={selectedRound} />
        )}
      </TableWidget.Table>
    </TableWidget>
  )
}
